import React from 'react'
import Logo from '../../images/mamacita-foundaation.png'
import {Link}  from 'react-router-dom'
import HeaderTopbar from '../HeaderTopbar'
import MobileMenu from '../MobileMenu'





const Header3 = (props) => {
    const SubmitHandler = (e) =>{
        e.preventDefault()
     }

     const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(	
	<div className="middle-header s2">
        <HeaderTopbar/>
        <div className="header-style-3">
            <div className="container">
                <div className="header-content">
                <div className="row">
                    <div className="col-lg-3 col-md-4 col-sm-4 col-4">
                        <div className="logo">
                            <Link onClick={ClickHandler} to="/" title=""> <img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/z7m9qdktz4pirbmm47zc" alt="MamacitaFoundation Logo"/></Link>
                        </div>
                    </div>
                    <div className="col-lg-8 d-lg-block d-none">
                        <nav>
                            <ul>
                                
                                   
                            
                                <li><Link onClick={ClickHandler} to="" title="">Who We Are</Link>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about" title="">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/Service" title="">Our Services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/team" title="">Team</Link></li>
                                <li><Link onClick={ClickHandler} to="/gallery" title="">Media</Link>
                                <ul>
                                
                                </ul>
                                </li>
                                
                                
                                
                                <li><Link onClick={ClickHandler} to="">News & Updates</Link>
                                
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/blog">News</Link></li>
                                    <li><Link onClick={ClickHandler} to="/event">Updates</Link></li>
                                </ul>
                                </li>
                                </ul>
                                
                                </li>
                        
                                <li><Link onClick={ClickHandler} to="/blog">Get Involved</Link>
                                    <ul>
                                        <li><Link onClick={ClickHandler} to="/donate" title="">Donate</Link></li>
                                        <li><Link onClick={ClickHandler} to="/volunteer" title="">Volunteer</Link></li>
                                        
                                    </ul>
                                </li>
                                <li><Link onClick={ClickHandler} to="/fqa" title="">FAQ</Link></li>
                                <li><Link onClick={ClickHandler} to="/contact" title="">Contact Us</Link></li>
                                <li><Link onClick={ClickHandler} to="/Uncrc" title="">UNCRC</Link></li>
                            </ul>
                        </nav>
                    </div>
                    <div className="col-lg-1 col-md-6 col-sm-6 col-6">
                        <div className="contact">
                            <div className="cart-search-contact">
                                <div className="header-search-form-wrapper">
                                    <button className="search-toggle-btn"><i className="fi flaticon-magnifying-glass"></i></button>
                                    <div className="header-search-form">
                                        <form onSubmit={SubmitHandler}>
                                            <div>
                                                <input type="text" className="form-control" placeholder="Search here..."/>
                                                <button type="submit"><i className="ti-search"></i></button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                               
                            </div>
                        </div>
                    </div>
                    <div className="col-md-2 col-sm-2 col-2">
                        <MobileMenu/>
                    </div>
                </div>
                
                    <div className="clearfix"></div>
                </div>
                
            </div>
        </div>
    </div>
    )
}

export default Header3;