import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import Foodbank from '../../components/FoodBank';
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Newsletter from '../../components/Newsletter'


const FoodbankPage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <Foodbank/>
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default FoodbankPage;

