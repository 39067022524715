import React from 'react';
import './style.css';

const Features = () => (
  <div class="card-container">
     <div className="section-title text-center">
      
      <h1 className="gradient__text">We offer a range of services such as poverty relief, health and wellbeing initiatives</h1>
    </div>

        <div class="card">
            <div class="card-content">
                <h3>Employability Skills Training & CV Clinic</h3>
                <p>Our programs offer training in employability skills, including
        CV writing and job search assistance, helping community members, especially the unemployed and
        young NEET, gain employment and reduce financial deprivation</p>
            </div>

        </div>
        <div class="card">
            <div class="card-content">
                <h3>Basic Supplies</h3>
                <p>We provide essential items such as children's clothes, shoes, books, furniture, bedding, clothing, and food to families and children in need.</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Emergency Aid</h3>
                <p>We provide prompt support during disasters, like the Covid-19 pandemic, assisting
            those at imminent risk of poverty due to loss of home, possessions, or livelihood.</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Language Classes</h3>
                <p>English language classes are available to support refugees and asylumseekers in
                  their integration into local communitie</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Student Support Services</h3>
                <p>Tailored assistance for students, including tutoring and mentorship, to
                  enhance their educational journey</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Social Networking & Befriending Services</h3>
                <p>Through coffee mornings, meet-up groups, and befriending
           services, we create opportunities for social connection, alleviating feelings of loneliness and fostering
          mental well-being.</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Mental Health Awareness and Support</h3>
                <p>We run workshops and information sessions on mental health,
      increasing awareness and providing support mechanisms.</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Food Bank</h3>
                <p>Our food bank initiative ensures that no one in our community faces hunger, providing
      nutritious food to those in need.</p>
            </div>
            
        </div>
        <div class="card">
            <div class="card-content">
                <h3>Settlement Support</h3>
                <p>Assisting newcomers, including migrants and asylum seekers, with settling into
      the community, from housing assistance to navigating local systems.</p>
            </div>
            
        </div>
      
    </div>
);

export default Features;