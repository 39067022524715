import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import Hero from '../../components/hero'
import Features from '../../components/Features'

import CtaSection from '../../components/cta'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import About from '../../components/about'
import hero1 from '../../images/slider/slide-7.jpg'

import Scrollbar from '../../components/scrollbar'
import abimg from '../../images/Volunteers.jpg'

const EventSection = React.lazy (() => import("../../components/event"))
const HomePage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <Hero Hero={hero1}/>
            
            <About AbImg={abimg} loading="lazy" />
            <Features/>
            <EventSection/>
            <CtaSection/>
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default HomePage;


// Here is a reference snippet of code from src\main-component\AboutPage\index.js:
// <About AbImg={abimg}/>
// <TeamSection/>
//you made changes her dont forget 