import React from 'react'
import TeamSection from '../team'
import './style.css'
import vimg from '../../images/Volunteer.jpeg'
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";
const Volunteer = () =>  {

    
    return(
        <div className="volunteer-page">
             <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="section-title text-center">
                           
                            <h2>How You Can Help</h2>
                        </div>
                        
                    </div>
                   
                    <div className="about-content">
                        
                        <p>Our food bank is always open to donations. </p>
                        <p>If you have clothing, furniture, or technology you no longer need or use that could be a blessing to someone else, please consider donating to us. </p>
                    <p>If you can offer your funds, skills, or time to assist us in our mission, it would mean the world. </p>
                    <p>We are always open to help from volunteers to run our events and services.</p>
                    <p>If you’re in need of assistance or would like to help, feel free to get in touch using the details below.</p></div>
                </div>
               
            <div className="volunteer-area section-padding">
           
                <div class="volunteer-wrap">
                    
                    <div class="container">
                        
                        <div class="row">
                            
                            <div class="col-lg-6">
                                <div class="volunteer-item">
                                    <div class="volunteer-img-wrap">
                                        <div class="volunter-img">
                                            <img src={vimg} alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div className="volunteer-contact">
                                    <div className="volunteer-contact-form">
                                        <form  className="contact-validation-active" id="contact-form">
                                            <div className="row">
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="name" id="MMERGE1" autoFocus  placeholder="Full Name"/>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group clearfix">
                                                    <input id="EMAIL"
                                                        className="form-control" name="email" placeholder="Email"/>
                                                </div>
                                                <div className="col-lg-6 col-md-6 col-sm-6 col-12 form-group">
                                                    <input type="text" className="form-control" name="subject" id="MMERGE2"
                                                        
                                                         placeholder="Subject"/>
                                                </div>
                                               
                                                <div className="col-lg-12 col-12 form-group">
                                                    <textarea className="form-control" name="note" id="note" placeholder="Little description about yourself..."></textarea>
                                                </div>
                                                <div className="submit-area col-lg-12 col-12">
                                                    <button type="submit" className="theme-btn submit-btn">Send Message</button>
                                                </div>
                                            </div>
                                        </form>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TeamSection teamClas={'v2'}/>
        </div>
    )
    
}
   

    
    export default Volunteer;
          
          
          
          
