import React from 'react'
import './style.css'
import {Link} from 'react-router-dom'
import tm1 from '../../images/team/team-1.jpeg'
import tm2 from '../../images/team/team-2.jpeg'


const TeamSection = (props) => {

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

    return(
        <section className={`team-section section-padding ${props.teamClas}`}>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col col-lg-6">
                        <div className="section-title text-center">
                            <span>Meet Our Team</span>
                            <h2>Our Expert</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col col-xs-12">
                        <p>At the heart of Mamacita Foundation is our dedicated management committee, a group of passionateindividuals led by Mrs. Ngozi Vivian Draghici. Each member brings a unique set of skills and
                           experiences, united by a shared commitment to supporting our community. Mrs. Ngozi Vivian Draghici, Chair and Chief Officer With a background as a recruitment consultant, Mrs. Draghici has a profound passion for empowering individuals through training and development, particularly focusing on employability skills and promoting health and well-being in society. Her
                           leadership and vision have been pivotal in steering the foundation since its inception.</p>
                        <div className="team-grids clearfix">
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={tm1} alt="" />
                                </div>
                                <div className="details">
                                    <h3><Link onClick={ClickHandler} to="/volunteer">Mrs. Ngozi Vivian Draghici</Link></h3>
                                    <p>CEO & Founder</p>
                                </div>
                            </div>
                            <div className="grid">
                                <div className="img-holder">
                                    <img src={tm2} alt="" />
                                </div>
                                <div className="details">
                                    <h3><Link onClick={ClickHandler} to="/volunteer">Tosede Onyema</Link></h3>
                                    <p>Secretary</p>
                                </div>
                            </div>
                           
                        </div>
                        <p>
                               Our Management Committee, is responsible for ensuring the charity's objectives are met, comprises
                               individuals appointed as charity trustees since the foundation's incorporation. They bring together a
                               wealth of expertise from various fields, contributing to our strategic decision-making and
                               governance . Our Volunteers The backbone of our operations, our approximately 70 volunteers, are integral to our
                               success. They come from diverse backgrounds, bringing a range of skills and experiences, and are
                               essential in implementing our programs and activities. Their dedication and hard work enable us to
                               reach and positively impact our target communities. Together, our team and volunteers embody the spirit of the Mamacita Foundation, working tirelessly
                               to uplift and support migrants, asylum seekers, BME members, and deprived communities in
                               Aberdeen and parts of Scotland.</p>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default TeamSection;