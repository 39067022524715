import React from 'react';
import {Link} from 'react-router-dom'

import abimg2 from '../../images/Objectives.png'
import './style.css'

const Welcome = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className=" col-sm-12 col-12">
                        <div className="about-img">
                            <img src={props.AbImg} alt=""/>
                          
                        </div>
                    </div>
                    <div className=" col-sm-12 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Our Story</h2>
                            </div>
                            <div className=''>
                            <p>Founded on the 23rd of September 2020, the Mamacita Foundation embarked on its mission to make a difference in the lives of those most vulnerable in our community, when it was made a Charitable Incorporated Organisation. 
                            </p>
                            <p>Beginning our journey in the heart of Scotland, our vision is to address the most pressing societal challenges faced by migrants, asylum seekers, members of Black and Minority Ethnic (BAME) groups and those experiencing extreme hardship. </p>
                            <p>Recognising the complex factors these groups are up against, from the great hurdle of poverty to the intricacies of navigating a new culture – our mission is to be a safe haven for those that need it the most. We offer not just immediate relief, but everlasting empowerment. </p>
                            <p>Our foundation was born in response to the growing need for an effective support system and compassionate care that caters to the distinct needs of these communities. </p>
                            <p>After only a few years, the Mamacita Foundation stands tall as a pillar of support which offers a wide range of programmes and services specially made to uplift, educate, and integrate. </p>
                            <p>Our name is synonymous with trust within the community, as we are well known for advocating for equality, promoting health and well-being, and giving opportunities for education and recreation. </p>
                            <p>The beating heart of our operation is the belief in the power of our community coming together, and the limitless potential each individual can rise to when they overcome adversity. </p>
                            <p>We seek to bring out the best in those we help by equipping them with the best resources, skills, and support networks we can offer. </p>
                            <p>As we continue to grow and adapt to the challenges that come, our core beliefs stay strong: we stand as a source of hope, relief and opportunity for positive change to those that need it the most.</p>
                            <p>We are not just our years of work, but each and every individual our efforts have touched and the shining light we’ve fostered in our community.</p>
                            <p>As we continue with this legacy, continuously adding to the strong foundation of our organisation, we strive to reach new heights and make out impact be felt wider through our service. </p>
                            <p>Join us in forging a clear path for those who need it the most. Together we can create a world that is vibrant, equitable and more inclusive. </p>
                           </div>
                            

                           
                            
                           
                            
                        </div>
                      


                    </div>
                    
                    
                </div>
                <div className="row">
                <div className="col-sm-12 col-12">
             <div className="about-content">
                <div className="section-title">
                
                 <h2>What We Do</h2>
                </div>
                <div className="case-bb-text">
                
                <p>The Mamacita Foundation has various programmes and outreaches to support migrants, asylum seekers, BAME groups and deprived communities in Aberdeen and other parts of Scotland. From securing housing to registering with local services, we’re here for you every step of the way. </p>
                <h3>We provide:</h3>
                <ul>
                <li><span className='subbo'>Basics and Essentials:</span> We provide necessary items such as food, furniture, bedding, clothing, and sources of heat for those facing financial issues. We have a regularly running food bank service to make sure no one goes hungry.</li>
                <li> <span className='subbo'>Support for Families:</span> We offer children’s clothes and shoes as well as books and stationery to support families in meeting their children’s educational and personal needs. </li>
                <br/>
                <h2>Our operations also cover three core areas:</h2><br/>
                <h4>Education</h4>
                <li><span className='subbo'>Training and Upskilling:</span> We conduct sessions to enhance employability. This includes topics such as writing a high-quality CV, tips for searching for jobs and teaching skills like crafting and recycling. These programmes are open to all, but we especially focused in helping young people not in education, employment, or training (NEET). Every Tuesday we host our CV & Job Clinic to assist those who need help finding work or moving to the next stage of their career.</li>
                <li><span className='subbo'>Enhancing vocational skills:</span> We deliver training to teach and enhance skills such as bed making, Akwete textile weaving, arts & crafts, and cooking. These skills help migrants and members of deprived communities the ability to participate in society fully and give young people the confidence to be independent.</li>
                <li><span className='subbo'>English Language Classes:</span> We provide language classes to refugees and asylum seekers so they can integrate into Scottish society and their new communities much easier. </li>
                   <br/>
                   <h4>Health Promotion</h4>
                   
                   <li><span className='subbo'>Health and Well-Being Campaigns: </span> We run information sessions and workshops covering various issues such as cancer awareness as well as hospital visitation programs.</li>
                   <li><span className='subbo'>Fitness Activities: </span> We offer programs to boost physical health with yoga sessions and other sports activities that encourage community bonding.</li>
                   <li><span className='subbo'>Sessions for Socialisation: </span> Be it coffee mornings, group meetup sessions or other events, we alleviate loneliness and isolation by giving opportunities to improve mental wellbeing through building a new social network. </li>
                    <br/>
                    <h2>Recreation</h2>
                   
                    <li><span className='subbo'>Community Events:  </span> We host events centred around local communities, to offer social and recreational activities as a way of regularly supporting mental health and reducing loneliness. Throughout the year we have various events to raise awareness for our services and give those newest to our community the chance to make new friends and build strong ties. </li>
                    <li><span className='subbo'>Cultural and Recreational Programmes: </span> We provide opportunities to contribute to multiculturalism, with events that offer cultural expression and engagement for a vibrant community life.  </li>
                   
                </ul>
               
                </div>
                </div>
                
                </div> 
               
                </div>
                
            </div>





            

        </div>
        
    )
}

export default Welcome;