import { initializeApp } from 'firebase/app';
import { getMessaging, onMessage, getToken } from "firebase/messaging";

// Replace this firebaseConfig object with the congurations for the project you created on your firebase console. 
const firebaseConfig = {
    apiKey: "AIzaSyC8M4etSclOSC3gLgfgeFKHZ1poHiyhtJI",
    authDomain: "mamacitafoundation.firebaseapp.com",
    databaseURL: "mamacitafoundation",
    projectId: "mamacitafoundation",
    storageBucket: "mamacitafoundation.appspot.com",
    messagingSenderId: "179451952938",
    appId: "1:179451952938:web:3cc114d6f6a48489462853",
    measurementId: "G-GKWQLN2GRT",
  
  };

initializeApp(firebaseConfig);

const messaging = getMessaging();


export const requestForToken = () => {
    return getToken(messaging, { vapidKey: 'BI5uEivsgnmGa0MfskQTcB6XiZHquHsD5P04wl2i20W9VFZ3kiXBdmgCECJgIBkI-oqGospV05Y4IJDZfUWrQ0I' })
      .then((currentToken) => {
        if (currentToken) {
          console.log('current token for client: ', currentToken);
          // Perform any other neccessary action with the token
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
        }
      })
      .catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
      });
  };

  export const onMessageListener = () =>
  new Promise((resolve) => {
    onMessage(messaging, (payload) => {
      console.log("payload", payload)
      resolve(payload);
    });
  });