import React, { useState } from 'react'
import './style.css'
import CountUp from 'react-countup'
import ScrollTrigger from 'react-scroll-trigger'

const CounterSection = (props) => {
    const [counterOn, setCounterOn] = useState(false)
    return(
        <div className={`tp-counter-area ${props.counter}`}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                    <ScrollTrigger onEnter={() => setCounterOn(true)} onExit={() => setCounterOn(false)}>
                        <div className="tp-counter-grids">
                            <div className="grid">
                                <div>
                                    <h2 className="odometer">
                                        {counterOn && <CountUp start={0} end={100} duration={2} delay={0}/>}+</h2>
                                        
                                </div>
                                <p>Donation</p>
                            </div>
                            <div className="grid">
                                <div>
                                    <h2 className="odometer">{counterOn && <CountUp start={0} end={200} duration={2} delay={0}/>}+</h2>
                                </div>
                                <p>Lives Touched</p>
                            </div>
                            <div className="grid">
                                <div>
                                <h2 className="odometer">{counterOn && <CountUp start={0} end={77} duration={2} delay={0}/>}+</h2>
                                </div>
                                <p>Volunteers</p>
                            </div>
                            <div className="grid">
                                <div>
                                <h2 className="odometer">{counterOn && <CountUp start={0} end={150} duration={2} delay={0}/>}+</h2>
                                </div>
                                <p>Projects</p>
                            </div>
                        </div>
                        </ScrollTrigger>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CounterSection;