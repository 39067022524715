import React from 'react'

import pmt1 from '../../images/checkout/img-1.png'
import pmt2 from '../../images/checkout/img-2.png'
import pmt3 from '../../images/checkout/img-3.png'
import pmt4 from '../../images/checkout/img-4.png'

import './style.css'

const Donate = (props) => {

    const SubmitHandler = (e) =>{
        e.preventDefault()
    }

    return(
        <div className="tp-donation-page-area section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-lg-8 offset-lg-2">
                        <div className="tp-donate-header">
                            <h2>Make a Donation</h2>
                        </div>
                        <div id="Donations">
                            <form onSubmit={SubmitHandler} action="#">
                                
                                
                                <div className="tp-doanation-payment">
                                    <h2 className='donationT'>For Donation Using Your Card</h2>
                                    <div className="tp-payment-area">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tp-payment-option" id="open4">
                                                   
                                                    <div id="open5" className="payment-name">
                                                        <ul>
                                                            <li className="visa"><input id="1" type="radio" name="size" value="30"/>
                                                                <label htmlFor="1"><img src={pmt1} alt=""/></label>
                                                            </li>
                                                            <li className="mas"><input id="2" type="radio" name="size" value="30"/>
                                                                <label htmlFor="2"><img src={pmt2} alt=""/></label>
                                                            </li>
                                                           
                                                            
                                                        </ul>
                                                        <div className="submit-area sub-btn">
                                                            <a href='pk_live_51M38dULnX2Yjx3msKoxuKI04JbyAZPM8do7oyGsKsIer2jOfaMyveT2Pj5qw28cTWIBdW2WB3ZXZf7sn9WOOJmzD00Df0HJl8D'>
                                                            <button type="submit" className="theme-btn submit-btn">Donate Now</button></a>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tp-doanation-payment">
                                    <h2 className='donationT'>For Donation Using Paypal </h2>
                                    <div className="tp-payment-area">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="tp-payment-option" id="open4">
                                                    
                                                    <div id="open5" className="payment-name">
                                                        <ul>
                                                        <li className="pay"><input id="4" type="radio" name="size" value="30"/>
                                                                <label htmlFor="4"><img src={pmt4} alt=""/></label>
                                                            </li>
                                                           
                                                        </ul>
                                                        <div className="submit-area sub-btn">
                                                        <form action="https://www.paypal.com/donate" method="post" target="_top">
<input type="hidden" name="hosted_button_id" value="SBZVLR849WWE4" />
<input type="image" src="https://www.paypalobjects.com/en_GB/i/btn/btn_donate_LG.gif" border="0" name="submit" title="PayPal - The safer, easier way to pay online!" alt="Donate with PayPal button" />
<img alt="" border="0" src="https://www.paypal.com/en_GB/i/scr/pixel.gif" width="6" height="1" />
</form>
                                                            
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                               
                            </form> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Donate;