import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route, } from "react-router-dom";


//import AboutPage from '../AboutPage'

import FoodbankPage from '../FoodbankPage';
import UncrcPage from '../UncrcPage';
import AboutPage from "../AboutPage"
import LegalPage  from "../LegalPage"
import  GalleryPage from "../GalleryPage"
import DonatePage from "../DonatePage"
import EventPage from "../EventPage"
import EventPageSingle from "../EventPageSingle"
import TeamPage from "../TeamPage"
import VolunteerPage from "../volunteerPage"
import BlogPage from "../BlogPage"
import BlogDetails from "../BlogDetails"
import FqaPage from "../FqaPage"
import ContactPage from "../ContactPage"
import Service from "../ServicePage"
import Uncrc from "../UncrcPage"
import Homepage from '../HomePage'
import Homepage2 from '../HomePage2'
import Homepage3 from '../HomePage3'


 const AllRoute = () => {

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path='FoodbankPage' element={<FoodbankPage />} />
          <Route exact path='/' element={<Homepage3 />} />
          <Route path='/about' element={
          <AboutPage />
      } />
          
          <Route path='/legal' element={
          <LegalPage />
          } />
          <Route path='/gallery' element={
          <GalleryPage />
          } />
          <Route path='/donate' element={
          <DonatePage />
          } />
          <Route path='/event' element={
          <EventPage />
          } />
          <Route path='/event-details' element={
          <EventPageSingle />
          } />
          <Route path='/team' element={
          <TeamPage />
          } />
          <Route path='/volunteer' element={
          <VolunteerPage />
          } />
          <Route path='/blog' element={
          <BlogPage />} />
          <Route path='/:slug' element={
          <BlogDetails />} />
          <Route path='/Fqa' element={
          <FqaPage />} />
          <Route path='/Service' element={
          <Service />} />
          <Route path='/contact' element={
          <ContactPage />
          }/>
          <Route path='/Uncrc' element={<UncrcPage />} />

          
         
         
        </Routes>
      </BrowserRouter>

    </div>
  );
}

export default AllRoute;
///blog/:slug