import React from 'react';
import {Link} from 'react-router-dom'


import './style.css'

const Legal = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                   
                    <div className=" col-sm-12 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Governance Structure</h2>
                            </div>
                            <div className=''>
                            <h3>Overview:</h3>
                            <p>
                            The Mamacita Foundation operates under a robust governance structure, ensuring the highest standards of accountability and transparency. It is managed by a dedicated Management Committee, responsible for overseeing the charity's objectives and ensuring that all assets and funds are utilized solely for the purposes for which they were intended. 
                            </p>
                           </div>
                           <div className=''>
                            <h3>Leadership:</h3>
                            <p>
                            Our committee is led by Mrs. Ngozi Vivian Draghici, who brings a wealth of experience
                            and passion for community empowerment. The committee also comprises members like Mr. Nwabueze Stanley Onyemah and Dr. Lawrence Emeka Modeme, each contributing unique skills and
                            perspectives to our mission.
                            </p>
                           </div>
                           <div className=''>
                            <h3>Compliance and Accountability:</h3>
                            <p>
                            We adhere to all regulatory requirements as a Charitable
                            Incorporated Organisation in Scotland. Our operations are conducted in accordance with the charity's
                            constitution, ensuring that all activities align with our stated objectives of poverty relief, educational
                            advancement, health promotion, and recreational activities.
                            </p>
                           </div>
                            
                        </div>
                    </div>
                    
                    
                </div>
                <div className="row">
                <div className="col-sm-12 col-12">
             <div className="about-content">
                <div className="section-title">
                
                 <h2>Policies</h2>
                </div>
                <div className="case-bb-text">
                <h3>Privacy Policy</h3>
                <p>We are committed to protecting the privacy and confidentiality of our beneficiaries, volunteers, and donors. Our privacy policy outlines how we collect, use, and safeguard personal
                    information in compliance with data protection laws. </p>
                    <p>Service Provision Policy: Our services are designed to be accessible and beneficial to our target
                    communities, including migrants, asylum seekers, and deprived groups. We ensure fairness and
                    equality in service delivery, with some services offered free and others at minimal cost to cover
                    expenses.</p>
                
                
                </div>
                <div className="case-bb-text">
                <h3>Service Provision Policy:</h3>
                <p>Our services are designed to be accessible and beneficial to our target
                 communities, including migrants, asylum seekers, and deprived groups. We ensure fairness and
                 equality in service delivery, with some services offered free and others at minimal cost to cover
                 expenses.</p>
                
                
                </div>
                <div className="case-bb-text">
                <h3>Community Engagement Policies:</h3>
                <p>Active community participation is at the heart of our operations. We
                     engage with our beneficiaries through various platforms, including local community centers, youth
                     clubs, and social media, to ensure our services are responsive to their needs and contribute to wider
                     community awareness.</p>
                
                
                </div>
                <div className="case-bb-text">
                <h3>Safeguarding Policy:</h3>
                <p>Safeguarding the welfare of our beneficiaries, especially children and vulnerable
                     adults, is a top priority. Our policy outlines the measures in place to protect them from harm and
                     provide a safe environment for all our activities.</p>
                
                
                </div>
                <div className="case-bb-text">
                <h3>Volunteer Policy:</h3>
                <p>Volunteers are crucial to our work. Our volunteer policy outlines the roles, responsibilities, and support available for volunteers, ensuring they are valued and can contribute
                 effectively to our mission.</p>
                </div>
                </div>
                
                </div> 
               
                </div>
                
            </div>
        </div>
        
    )
}

export default Legal;