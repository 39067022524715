import React from 'react'
import {Link}  from 'react-router-dom'
import Logo from '../../images/mamacita-foundaation.png'
import n1 from '../../images/instragram/1.jpg'
import n2 from '../../images/instragram/2.jpg'
import n3 from '../../images/instragram/3.jpg'
import n4 from '../../images/instragram/4.jpg'
import n5 from '../../images/instragram/5.jpg'
import n6 from '../../images/instragram/6.jpg'

import {
    FaInstagram,
    FaTiktok,
    FaYoutube,
    FaTwitter,
    FaTelegram,
   
    FaLinkedin,
  } from "react-icons/fa";

import './style.css'

const Footer = (props) =>{

    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }

  return(
    <footer className="tp-ne-footer">
        <div className="tp-site-footer">
            <div className="tp-upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                <img src={Logo} alt="ins"/>
                                </div>
                                <p>A beacon of hope and support for those who arrive on our shores seeking a new beginning  </p>
                                <ul>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                                <a href="https://www.facebook.com/p/Mamacita-Foundation-100068553247818">
                                                    <i className="ti-facebook"></i>
                                                </a>
                                            </div>
                                        </div>
                                    </li>
                                    
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                            <a href="https://www.linkedin.com/posts/mamacita-foundation_acvo-aberdeen-cfine-activity-7071850085965287424-QozX">
                                                <FaLinkedin/>
                                               </a>
                                                
                                              
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                                <a href="https://twitter.com/Mamacitafoundtn">
                                               
                                                <FaTwitter />
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                               <a href="https://www.instagram.com/mama.citafoundationuk/">
                                               <FaInstagram/>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                                <a href='https://t.me/+jZYLvhHgN3VmZGFk'>
                                                <FaTelegram/> 
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                                <a href='https://www.tiktok.com/@mamacitafoundatio?_t=8kwuaI7eOVp&_r=1'>
                                                <FaTiktok/>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="parallax-wrap">
                                            <div className="parallax-element">
                                                <a href="https://www.youtube.com/@MamacitaAfricanFoundation">
                                                <FaYoutube/>
                                                </a>
                                                
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                               
                                    <a href='https://www.gov.uk/'>
                                    <li>National Insurance</li></a>
                                    <a href='https://www.gov.uk/dvla'>
                                    <li>Driver's License</li></a>
                                    <a href='https://www.nhs.uk'>
                                    <li>GP Registratio</li></a>
                                    <a href='https://www.youn.org'>
                                    <li>Young Scot Regist</li></a>
                                    <a href='https://www.aberdeencity.gov.uk'>
                                    <li>Aberdeen City Council Tax</li></a>
                                    <a href='https://www.gov.uk'>
                                    <li>Lost BRP (Biometric Residence Perm)</li></a>
                                    <a href='https://www.aberdeencity.gov.uk'>
                                   <li>Aberdeen City School Registration</li></a>
                                   <a href='https://www.gov.uk/browse/visas-immigration'>
                                   <li>UK Immigration Website</li></a>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-2 col-md-6 col-sm-12 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Quick Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service">Our services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/donate">Donate</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/event">Our Event</Link></li>
                                    <li><Link  onClick={ClickHandler} to="/legal">Legal</Link></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-lg-3 offset-lg-1 col-md-6 col-sm-12 col-12">
                            <div className="widget market-widget tp-service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact </h3>
                                </div>
                                <div className="contact-ft">
                                    <ul>
                                        <li><i className="fi flaticon-pin"></i>Hilton Convention Centre, 13 Smithfield Road AB24 4NR, <br/>Aberdeen, Scotland.</li>
                                        <li><i className="fi flaticon-call"></i>+44(0)7449826988</li>
                                        <li><i className="fi flaticon-envelope"></i>info@mamacitafoundationuk.com</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                            <div className="widget instagram">
                                <div className="widget-title">
                                    
                                </div>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="tp-lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <p className="copyright">&copy; 2024 Mamacita Foundation. All rights reserved</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
  )
} 

export default Footer;