import React from 'react'
import {Link} from 'react-router-dom'

import './style.css'





  
  
  
const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-style-2">
          
            <div className="container">
              
                <div className="row">
                <div class="main__bg layer1"></div> 
                   <div class="main__bg layer2"></div> 
                    <div className=" col-sm-12 col-12">
                        <div className="about-img">
                            <img src={props.AbImg}  loading='lazy'alt=""/>
                            
                        </div>
                    </div>
                    <div className=" col-sm-12 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h2>Never Feel Alone</h2>
                            </div>
                            <p> We belive that by surpporting each individual's journey, we are weaving a stronger, more inclusive community fabric-one where divercity is celebrated, and every member is valued. Join us as we embark on this journey of transformation and solidarity. Together, we can create a future where everyone in Aberdeen and beyond has the chance to live a life full of potential and dignity. Welcome to our comunity. Welcome to a place where change begins. Welcome to Mamacita Foundation. </p>
                           
                            
                            <div className="btns">
                                <div><Link  onClick={ClickHandler} className="theme-btn" to="/about">Know More</Link></div>
                            </div>

                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    )
}

export default About;