import React from 'react';
import './style.css'
import { useFormFields, useMailChimpForm } from "use-mailchimp-form";

const Newsletter = (props) => {
    const url = "https://gmail.us10.list-manage.com/subscribe/post?u=d311d0139408050e25720c6ce&amp;id=5841ec5ef8&amp;f_id=0002e6e5f0";
    // The url looks like the url below:
    // https://aaaaaaaaa.us20.list-manage.com/subscribe/post?u=xxxxxxxxxxxxxxxxxx&amp;id=yyyyyyyyyy
    const {
        loading,
        error,
        success,
        message,
        handleSubmit
      } = useMailChimpForm(url);
    const { fields, handleFieldChange } = useFormFields({
      EMAIL: "",
    });
    return(
        <section className={`news-letter-section ${props.NewsClass}`}>
            <div className="container">
                <div className="news-letter-wrap">
                    <div className="row justify-content-center">
                        <div className="col col-lg-10 col-md-8">
                            <div className="newsletter">
                                <h3>Subscribe to our Newsletter</h3>
                                <div className="newsletter-form">
                                    <form  onSubmit={event => {
                                       event.preventDefault();
                                       handleSubmit(fields);
}                                     }>
                                        <div>
                                            <input id="EMAIL"
                                           
                                            type="email"
                                            value={fields.EMAIL}
                                            onChange={handleFieldChange}className="form-control"/>
                                        </div>
                                        <button className="bigCursor" type="submit">Subscribe</button>
                                    </form>
                                    {loading && "submitting"}
                                    {error && message}
                                    {success && message} 
                                </div>
                                <p>By submitting your email address above, you agree to receving MamacitaFoundation news and offers by email. You can choose to stop receving these notifications at any time in the future</p>

                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
     )
        
}

export default Newsletter;
