

import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../../Client';


import './style.css'

const BlogSidebar = () => {

    const SubmitHandler = (e) =>{
       e.preventDefault()
    }


    const [recentPosts, setRecentPosts] = useState([]);
    const [categories, setCategories] = useState([]);

  useEffect(() => {
    client
      .fetch(
        `*[_type == "post"] | order(publishedAt desc) {
          title,
          slug,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt
          },
          publishedAt
        }[0...5]`
      )
      .then((data) => setRecentPosts(data))
      .catch();
    
      
  }, []);

  

    return(
        <div className="col col-lg-4 col-12">
            <div className="tp-blog-sidebar">
                <div className="widget search-widget">
                    <h3>Search Here</h3>
                    <form onSubmit={SubmitHandler}>
                        <div>
                            <input type="text" className="form-control" placeholder="Search Post.."/>
                            <button type="submit"><i className="ti-search"></i></button>
                        </div>
                    </form>
                </div>
                
                
                <div className="widget recent-post-widget">
                    <h3>Recent posts</h3>
                    <div className="posts">
                        
                    {recentPosts.map((post) => (
                        <div className="post" key={post.slug.current}>
                            <div className="img-holder HI">
                                    <img src={post.mainImage.asset.url} alt={post.title} />
                                </div>
                            <div className="details">
                            <h4><Link to={"/" + post.slug.current}>{post.title}</Link></h4>
                                <span to="/blog-details" className="date"> <i className="ti-calendar"></i> {new Date(post.publishedAt).toLocaleDateString()}</span>
                            </div>
                        </div>
                      ))}
               
                    </div>
                </div>
               
            </div>
        </div>
     )
        
}

export default BlogSidebar;
// work on Tags latter