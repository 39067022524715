import React from 'react'
import './style.css'
import {Aldi, Sainsbury, Neighbourly, Asda, Costco, Aberdeen,Sponsor,Sponsor1,Sponsor2,Sponsor4,Sponsor5,Sponsor7} from './import.js'
import { Divider } from '@material-ui/core'

const Features = (props) => {

    return(
        <div className={`features-area ${props.Features}`}>
            <div className="container">
                <div className="row">
                
                    
                </div>
                
                    <div className="transformer__brand section__padding"> 
                    
                    
                     <figure><img src={Aldi} alt="ins" loading='lazy'/></figure>
                    <figure><img src={Sainsbury} alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/e0trozzojnx8pijeby9y" alt="Asda" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/jislnbebp9dfbouuwh6q" alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/ewon7m6p0i9p5lm8e8iy" alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/n0knvjftasumrs1ieiv3" alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/aoty9aburv7zowpe5e55"alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/lpdsgjmx7szprgxxafz4" alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/ljjugc5kgywn1sohua6q" alt="ins" loading='lazy'/></figure>
                    <figure><img src="https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/r58hmkbqou3iunubxypc" alt="ins" loading='lazy'/></figure>
                    </div> 
</div>
</div>
           
    )
}

export default Features;