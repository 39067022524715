import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'

import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Service from '../../components/Service'


const ServicePage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'Our Services'} pagesub={'Welcome'}/>   
            <Service/>        
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default ServicePage;

// <Features Features={'features-area-2'}/>