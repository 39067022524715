const data = [
    {
      question: "Q1: What services does the Mamacita Foundation provide?",
      answer:
        "A1: We offer a range of services including poverty relief (provision of basic supplies, emergency aid), educational advancement (vocational skills training, language classes), and health and wellbeing initiatives (social networking events, health awareness campaigns, fitness activities)."
    },
    {
      question: "Q2: How can I get involved with the Mamacita Foundation?",
      answer:
        "A2: There are several ways to get involved! You can volunteer your time and skills, participate in our training programs, or support us through donations. Visit our 'Get Involved' page for more information on how to join our community efforts."
    },
    {
      question: "Q3: Are the Foundation's services free?",
      answer:
        "A3: Yes, most of our services are offered free of charge to benefit the public. In certain cases,we require donations to cover the costs of specific services."
    },
    {
      question: "Q4: Who can benefit from the Mamacita Foundation’s services?",
      answer:
        "A4: Our primary beneficiaries are migrants, asylum seekers, members of BME, and deprived communities within Aberdeen and parts of Scotland. However, our services are open to anyone in Aberdeen who believes they can benefit from them."
    },
    {
      question: "Q5: How can I donate to the Mamacita Foundation?",
      answer:
        "A5: Donations can be made directly through our website. Visit our 'Donations'  page for more information on how to contribute financially to support our causes."
    },
    {
      question: "Q6: Does the Foundation provide employment support?",
      answer:
        "A6: Yes, we offer employability skills training, CV writing assistance, and job search support to help community members, especially the unemployed and young NEET, gain emp"
    },
    {
      question: "Q7: Can non-English speakers access services at the Foundation?",
      answer:
        "A7: Absolutely. We provide English language classes to aid non-English speakers and also strive to have multilingual volunteers to assist in various languages"
    },
    {
      question: "Q8: How does the Foundation support health and wellbeing?",
      answer:
        "A8: We organize various health and wellbeing activities, pop-in sessions. including yoga sessions, sports activities, and health awareness workshops. We also facilitate social networking events to combat loneliness and improve mental wellbeing."
    },
    {
      question: "Q9: Where are your services located?",
      answer:
        "A9: Our services are primarily based in Aberdeen, Scotland. For more specific locations and event details, please check our 'Events' page or contact us directly."
    },
    {
      question: "Q10: How can I stay updated on the Foundation’s activities?",
      answer:
        "A10: You can stay informed by following us on social media, subscribing to our newsletter, and regularly visiting our 'News & Updates' section on the website."
    },
  ];
  
  export default data;
  