import React from 'react';
import {Link} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'

import './style.css'

import client from '../../Client';
import { useState, useEffect } from'react'




const BlogList = () => {




    const [posts, setPosts] = useState([])

    useEffect(() => {
      client
        .fetch(
          `*[_type == "post"] {
          title,
          slug,
          body,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt
          },
          publishedAt,
          author-> {
            name,
            image {
                asset->{
                  url
                }
              },
            bio,
            // add more author fields as needed
          }
        }`
        )
        .then((data) => setPosts(data))
        .catch()
    }, [])









    return(
        <section className="tp-blog-pg-section section-padding">












            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12">
                        <div className="tp-tp-blog-content">
                        {posts.map((post) => (
                            <article key={post.slug.current}>
                            <div className="post format-standard-image">
                                <div className="entry-media">
                                    <img src={post.mainImage.asset.url} alt={post.title} />
                                </div>
                                <ul className="entry-meta">
                                    <li><Link to="/blog-details"><img src={post.author.image.asset.url} alt={`By ${post.author.name}`} />{`By ${post.author.name}`}</Link></li>
                                    <li><Link to="/blog-details"> <i className="ti-calendar"></i> {new Date(post.publishedAt).toLocaleDateString()}</Link></li>
                                    
                                </ul>
                                <h3><Link to={"/" + post.slug.current}>{post.title}</Link></h3>
                                <p>{`${post.body[0].children[0].text.substring(0,100)}...`} </p>
                                <Link to={"/" + post.slug.current}  key={post.slug.current} className="read-more">Read More...</Link>
                                
                              
                           
                            </div>
                            
                            </article>
                             ))}
                        </div>
                    </div>
                    <BlogSidebar/>
                </div>
            </div>
        </section>
     )
        
}

export default BlogList;
//<p>{`${post.body[0].children[0].text.substring(0,100)}...`} </p>