import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'
import Legal from '../../components/legal';

import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const LegalPage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'Legal'} pagesub={'& Governance'}/> 
            <Legal/>
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default LegalPage;

// <Features Features={'features-area-2'}/>