import React from 'react';
import AllRoute from '../router'
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';
import Notification from '../../components/Notification';

import { ImageEngineProvider } from "@imageengine/react"
const App = () => { 

  return (
    <div className="App" id='scrool'>   
    <ImageEngineProvider deliveryAddress="https://4zv7n5ye.dev.cdn.imgeng.in/">
          <AllRoute/>
          <ToastContainer/>
          <Notification />
          </ImageEngineProvider>
    </div>
  );
}

export default App;
