import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import client from '../../Client';

import './style.css';

const EventSection = (props) => {
  const [events, setEvents] = useState([]);
  const [limit, setLimit] = useState(3); // Initial limit of events to display
  const [hasNextPage] = useState(true); // Flag to indicate if more pages exist

  useEffect(() => {
    const fetchEvents = async () => {
      const data = await client.fetch(
        `*[_type == "event"] {_id, title, slug, mainImage {asset -> { _id, url }}, publishedAt, author->{ name, image {asset->{ url }}}}[0...${limit}]`
      );
      setEvents(data);
      hasNextPage(data.length === limit);
    };

    fetchEvents();
  }, [limit]); // Re-fetch data when limit changes

  const handleLoadMore = async () => {
    const nextLimit = limit + 3; // Increase limit by 3 for next page
    const data = await client.fetch(
      `*[_type == "event"] {_id, title, slug, mainImage {asset -> { _id, url }}, publishedAt, author->{ name, image {asset->{ url }}}}[${limit}...${nextLimit}]`
    );

    setEvents((prevEvents) => [...prevEvents, ...data]); // Append new data
    setLimit(nextLimit);
    hasNextPage(data.length === 3); // Check if there are more pages after this one
  };

  return (
    <section className="event-section section-padding">
      <div className="container">
        <div className="section-title text-center">
          <span>Our Events</span>
          <h2>Upcoming Events</h2>
        </div>
        <div className="row">
          <div className="col col-xs-12">
            <div className="event-grids clearfix">
              {events.map((event) => (
                <article key={event._id}>
                  <div className="grid">
                    <div className="img-holder">
                      <img src={event.mainImage.asset.url} alt={event.title} loading="lazy" />
                    </div>
                    <div className="details">
                      <ul className="entry-meta">
                        <li>
                          <Link to="/blog-details">
                            <i className="ti-calendar"></i> {new Date(event.publishedAt).toLocaleDateString()}
                          </Link>
                        </li>
                        
                      </ul>
                      <h3>
                        <Link to={"/" + event.slug.current}>{event.title}</Link>
                      </h3>
                    </div>
                  </div>
                </article>
              ))}
            </div>
            {hasNextPage && (
              <a className="theme-btn" onClick={handleLoadMore}>
                More Events
              </a>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default EventSection;