import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'
import Accordion from '../../components/Fqa/Accordion';
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'


const FqaPage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'Frequently Asked Questions'} pagesub={'Welcome'}/>           
            <Accordion />
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default FqaPage;

// <Features Features={'features-area-2'}/>