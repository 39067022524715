import React from 'react';
import {Link, useParams} from 'react-router-dom'
import BlogSidebar from '../BlogSidebar'
import './style.css'

import blog2 from '../../images/blog-details/author.jpg'

import client from "../../Client"
import BlockContent from "@sanity/block-content-to-react"
import { useState, useEffect } from "react"

const BlogSingle = () => {


    const [singlePost, setSinglePost] = useState([])
  
  const { slug } = useParams()

  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
        title,
        body,
        author-> {
            name,
            image {
                asset->{
                  url
                }
              },
            bio,
            // add more author fields as needed
          },
        mainImage {
          asset -> {
            _id,
            url
          },
          alt
        }
      }`
      )
      .then((data) => setSinglePost(data[0]))
    .catch()
  }, [slug])



    const submitHandler = (e) => {
        e.preventDefault()
    }

    return(
        <section className="tp-blog-single-section  section-padding">
            

            
            <div className="container">
                <div className="row">
                    <div className="col col-lg-8 col-12">
                        <div className="tp-tp-blog-content clearfix">
                            <div className="post">
                            {singlePost.mainImage && singlePost.mainImage.asset && (
                                <div className="entry-media">
                                <img
                                src={singlePost.mainImage.asset.url}
                                alt={singlePost.title}
                                title={singlePost.title}
                                className="blog__image rounded-t"
                              />
                                </div>)}
                                
                                
                                <BlockContent
                                 blocks={singlePost.body}
                                 projectId="`${process.env.projectId}`"
                                 dataset="`${process.env.dataset}`"
                                />
                            </div>
                            <div className="tag-share clearfix">
                                <div className="tag">
                                   {/*  <ul>
                                        <li><Link to="/blog-details">Business</Link></li>
                                        <li><Link to="/blog-details">Marketing</Link></li>
                                        <li><Link to="/blog-details">SsEO</Link></li>
                                    </ul>*/}
                                </div>
                                <div className="share">
                                    <ul>
                                        <li><Link to="/blog-details"><i className="ti-facebook"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-twitter-alt"></i></Link></li>
                                        <li><Link to="/blog-details"><i className="ti-instagram"></i></Link></li>
                                    </ul>
                                </div>
                            </div> 
                            <div className="author-box">
                               {/*
                                <div className="author-avatar">
                                    <Link to="/blog-details" target="_blank"><img src={blog2} alt=""/>  </Link>
                                </div>*/}
                                <div className="author-content">
                                    <Link to="/blog-details" className="author-name"> {singlePost.title}</Link>
                                    <p></p>
                                   
                                </div>
                            </div> 
                            <div className="more-posts clearfix">
                            {/*
                                <div className="previous-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">Previous</span>
                                    </Link>
                                </div>
                                <div className="next-post">
                                    <Link to="/blog-details">
                                        <span className="post-control-link">Next post</span>
                                    </Link>
                                </div>*/}
                            </div>
                        </div>
                        
                    </div>
                    <BlogSidebar/>
                </div>
            </div>
        </section>
     )
        
}

export default BlogSingle;

// just incase she needs comments

/*<div className="tp-blog-single-section wrap-even">
                            <div className="comments-area">
                                <div className="comments-section">
                                    <h3 className="comments-title">Comments</h3>
                                    <ol className="comments">
                                        <li className="comment even thread-even depth-1" id="comment-1">
                                            <div id="div-comment-1">
                                                <div className="comment-theme">
                                                    <div className="comment-image"> <img src={blog3} alt=""/> </div>
                                                </div>
                                                <div className="comment-main-area">
                                                    <div className="comment-wrapper">
                                                        <div className="comments-meta">
                                                            <h4>John Abraham <span className="comments-date">Octobor 28,2018 At 9.00am</span></h4>
                                                        </div>
                                                        <div className="comment-area">
                                                            <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                                            <div className="comments-reply">
                                                                <Link className="comment-reply-link" to="/blog-details"><i className="fa fa-reply" aria-hidden="true"></i><span>Reply</span></Link>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <ul className="children">
                                                <li className="comment">
                                                    <div>
                                                        <div className="comment-theme">
                                                            <div className="comment-image"> <img src={blog4} alt=""/></div>
                                                        </div>
                                                        <div className="comment-main-area">
                                                            <div className="comment-wrapper">
                                                                <div className="comments-meta">
                                                                    <h4>Lily Watson <span className="comments-date">Octobor 28,2018 At 9.00am</span></h4>
                                                                </div>
                                                                <div className="comment-area">
                                                                    <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                                                    <div className="comments-reply">
                                                                        <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <ul>
                                                        <li className="comment">
                                                            <div>
                                                                <div className="comment-theme">
                                                                    <div className="comment-image"><img src={blog5} alt=""/> </div>
                                                                </div>
                                                                <div className="comment-main-area">
                                                                    <div className="comment-wrapper">
                                                                        <div className="comments-meta">
                                                                            <h4>John Abraham <span className="comments-date">Octobor 28,2018 At 9.00am</span></h4>
                                                                        </div>
                                                                        <div className="comment-area">
                                                                            <p>I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, </p>
                                                                            <div className="comments-reply">
                                                                                <Link className="comment-reply-link" to="/blog-details"><span><i className="fa fa-reply" aria-hidden="true"></i> Reply</span></Link>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </li>
                                            </ul>
                                        </li>
                                    </ol>
                                </div>
                            </div> 
                            <div className="comment-respond">
                                <h3 className="comment-reply-title">Leave a Comment</h3>
                                <form method="post" id="commentform" className="comment-form" onSubmit={submitHandler}>
                                    <div className="form-inputs">
                                        <input placeholder="Name" type="text"/>
                                        <input placeholder="Email" type="email"/>
                                        <input placeholder="Website" type="url"/>
                                    </div>
                                    <div className="form-textarea">
                                        <textarea id="comment" placeholder="Write Your Comments..."></textarea>
                                    </div>
                                    <div className="form-submit">
                                        <input id="submit" value="Reply" type="submit"/>
                                    </div>
                                </form>
                            </div>
                        </div>*/