import React, {Fragment} from 'react';
import Navbar3 from '../../components/Navbar3'
import PageTitle from '../../components/pagetitle'
import Uncrc from '../../components/Uncrc'

import CounterSection from '../../components/counter'
import Newsletter from '../../components/Newsletter'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import abimg from '../../images/Volunteers.jpg'

const UncrcPage =() => {
    return(
        <Fragment>
            <Navbar3/>
            <PageTitle pageTitle={'UNCRC PAGE'} pagesub={'Welcome'}/> 
            <Uncrc />
            <CounterSection/>
            <Newsletter/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default UncrcPage;

// <Features Features={'features-area-2'}/>