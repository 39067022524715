import React, { Component } from "react";
import Slider from "react-slick";
import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Hero1  from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-3.jpg'
import hero3 from '../../images/slider/slide-4.jpg'
import Popup from "../Popup";
import './style.css'



class Hero extends Component {
    
    render() {

        var settings = {
            dots: false,
            arrows: true,
            speed: 1200,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 2500,
            fade: true
        };

        return (
            <section className="hero hero-slider hero-style-1">
                <Slider {...settings}>
                    <div className="slide">
                        <div className="slide-inner responsive"  style={{ backgroundImage: `url("https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/vwaamz1dnhs7utlymquw")` }} loading="lazy">
                            <div className="container">
                                <div className="col  slide-caption">
                                    <div className="slide-title">
                                        <h2>Mamacita Foundation</h2>
                                    </div>
                                    <div className="col-lg-6 slide-subtitle">
                                        <p className="hope">A Beacon of hope and support for those who arrive on our shores seeking new beginning</p>
                                        
                                    </div>
                                    <div className="btns">
                                    <a className="theme-btn">
                                        <Popup />
                                    </a>
                                        <Link to="/about" className="theme-btn-s2">Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url("https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/afnwnw0rjompxhybizut")` }} loading="lazy">
                             <div className="container">
                                <div className="col  slide-caption">
                                    <div className="slide-title">
                                    <h2>Mamacita Foundation</h2>
                                    </div>
                                    <div className="col-lg-6 slide-subtitle">
                                        <p className="hope">A Beacon of hope and support for those who arrive on our shores seeking new beginning</p>
                                        
                                    </div>
                                     
                                    <div className="btns">
                                    <a className="theme-btn">
                                        <Popup />
                                    </a>
                                           
                                        <Link to="/about" className="theme-btn-s2">Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="slide">
                        <div className="slide-inner" style={{ backgroundImage: `url("https://res.cloudinary.com/dgegrs2uj/image/upload/f_auto,q_auto/gfi0rebp0bj9oxopybxs")` }} loading="lazy">
                            <div className="container">
                                <div className="col  slide-caption">
                                    <div className="slide-title">
                                    <h2>Mamacita Foundation</h2>
                                    </div>
                                    <div className="col-lg-6 slide-subtitle">
                                        <p className="hope">A Beacon of hope and support for those who arrive on our shores seeking new beginning</p>
                                        
                                    </div>
                                    <div className="btns">
                                    <a className="theme-btn">
                                        <Popup />
                                    </a>
                                        <Link to="/about" className="theme-btn-s2">Know More</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Slider>
            </section>
        )
    }
}

export default Hero;