import React, { useState, useEffect } from 'react';
import './style.css'; // Import your CSS file
import { Link } from 'react-router-dom';

function Popup() {
  const [showPopup, setShowPopup] = useState(false);
  const [currentDate, setCurrentDate] = useState(null); // Initially set to null

  const allowedDays = [0, 1, 2, 3, 4, 5, 6]; // Array of allowed days (Sunday = 0, Saturday = 6)

  // Dynamically render content based on the current day
  const popupContent = () => {
    if (!currentDate) return null; // Handle potential initial null state
    const dayOfWeek = currentDate.getDay();
    return dayOfWeek === 0 || // Use logical OR (||) for clarity
           dayOfWeek === 1 ||
           dayOfWeek === 2 ||
           dayOfWeek === 3 ||
           dayOfWeek === 4 ||
           dayOfWeek === 5 ? (
      <p>SignUp to our FoodBank using the button below</p>
    ) : dayOfWeek === 6 ? (
      
      <p>butto n dosent show today</p>
    ) : (
      <p>
        Hello, Happy Monday! Today's your last chance to signup for the FoodBank.
      </p>
    );
  };

  // Fetch current date on component mount and store in state
  useEffect(() => {
    const initialDate = new Date();
    setCurrentDate(initialDate);

    // Optionally, consider setting a local storage flag for persistence:
    const hasAlreadySeenPopup = localStorage.getItem('foodbankPopupSeen');
    if (hasAlreadySeenPopup) {
      setShowPopup(false); // Don't show if already seen today
    }
  }, []); // Empty dependency array to run only on mount

  const handleClick = () => {
    setShowPopup(!showPopup);
  };

  return (
    <div>
      <a onClick={handleClick} >Food Bank
      </a>
      
      {showPopup && ( // Only render popup if showPopup is true
        <div className="popup-body">
          <a onClick={handleClick} className="close-btn">X</a>
          {popupContent()}
          <a
            href="https://docs.google.com/forms/d/e/1FAIpQLSeCpjEkcG6rEzbYiGMkRExzU--JML5E80-xZF9ixcRz2VTRcg/viewform"
            className="theme-btn"
          >
            SignUp Here
          </a>
        </div>
      )}
    </div>
  );
}

export default Popup;