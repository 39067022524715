import React from 'react';
import {Link} from 'react-router-dom'

import abimg2 from '../../images/Objectives.png'
import './style.css'

const Uncrc = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="about-style-2">
            <div className="container">
                <div className="row">
                    <div className=" col-sm-12 col-12">
                        
                    </div>
                    <div className=" col-sm-12 col-12">
                        <div className="about-content">
                            <div className="section-title">
                                <h3>Welcome to Mamacita Foundation's UNCRC Resource Page</h3>
                            </div>
                            <div className=''>
                            <p>At Mamacita Foundation, we are committed to upholding the rights of all children, including immigrant children, as outlined in the United Nations Convention on the Rights of the Child (UNCRC). Our dedication to this cause drives our efforts to provide support and advocacy for vulnerable children and families within immigrant communities. 
                            </p>
                            <div className="section-title">
                
                               <h3>Understanding the UNCRC</h3>
                             </div>
                            <p>The UNCRC is a fundamental international human rights treaty that sets out the civil, political, economic, social, and cultural rights of children. It defines the basic rights that every child should have, regardless of their nationality, ethnicity, or immigration status. </p>
                            <div className="section-title">
                
                               <h3>Resources for Immigrant Children</h3>
                             </div>
                            <p>On this page, you will find a collection of resources aimed at promoting awareness and understanding of the UNCRC, with a specific focus on immigrant children. These resources include downloadable materials, informative guides, and links to external sources providing further information on children's rights and immigration-related issues.</p>
                            <div className="section-title">
                
                               <h3>How We Support Immigrant Children's Rights</h3>
                             </div>
                            <p>At Mamacita Foundation, we are deeply committed to supporting the rights of immigrant children in tangible ways. Through our various programs and initiatives, we strive to create safe and inclusive spaces where immigrant children can thrive and access the support they need to fulfill their potential. From educational assistance to legal advocacy, we work tirelessly to ensure that immigrant children receive the protection and care they deserve.</p>
                            <div className="section-title">
                
                               <h3>Impact Stories</h3>
                             </div>
                            <p>Explore real-life stories and testimonials from immigrant children and families who have benefited from our programs and services. These stories highlight the positive impact of our efforts and underscore the importance of protecting and promoting the rights of immigrant children within our communities. </p>
                            <div className="section-title">
                
                               <h3>Additional Resources</h3>
                             </div>
                            <p>Discover a wealth of additional resources and information related to children's rights and immigration issues. Whether you're a parent, educator, advocate, or concerned individual, these resources offer valuable insights and guidance on supporting immigrant children and promoting their well-being.</p>
                            <div className="section-title">
                
                               <h3>Get Involved</h3>
                             </div>
                            <p>Interested in joining our efforts to support immigrant children's rights? Learn how you can get involved with Mamacita Foundation and make a difference in the lives of vulnerable children and families. Your support and advocacy are crucial in ensuring that all children, regardless of their background, have the opportunity to thrive and succeed.</p>
                            <div className="section-title">
                
                               <h3>Contact Us</h3>
                             </div>
                            <p>Have questions or need more information? Contact us today to learn more about our work and how you can contribute to our mission of empowering immigrant children and families.</p>
                            <p>Together, we can build a brighter future for all children, where their rights are respected, protected, and upheld. Join us in championing the rights of immigrant children and creating a world where every child can flourish.</p>
                            
                           </div> 

                                  <div className="section-title">
                                  <a href='https://www.cypcs.org.uk/rights/uncrc/'>
                                 <h3>Uncrc</h3>
                                 </a>
                                    <a href='https://www.gov.scot/policies/human-rights/childrens-rights/'>
                                 <h3>Children's rights</h3>
                                 </a>
                                 
                                 <a href='https://www.togetherscotland.org.uk/about-childrens-rights/monitoring-the-uncrc/incorporation-of-the-un-convention-on-the-rights-of-the-child/'>
                                 <h3>Together Scotland</h3>
                                 </a>
                                 <a href='https://www.parliament.scot/'>
                                 <h3>Scottish parliament</h3>
                                 </a>
                                 
                              </div>
                             
                        </div>
                    </div>
 
                </div>
            </div>
        </div>
    )
}

export default Uncrc;