import React, { Component } from 'react';
import ReactFancyBox from 'react-fancybox'
import 'react-fancybox/lib/fancybox.css'

import Gallery_1 from '../../images/gallery/img-1.svg';
import Gallery_2 from '../../images/gallery/img-2.svg';
import Gallery_3 from '../../images/gallery/img-3.svg';

import Gallery_5 from '../../images/gallery/img-5.svg';
import Gallery_6 from '../../images/gallery/img-6.svg';
import Gallery_7 from '../../images/gallery/img-1.svg';
import Gallery_8 from '../../images/gallery/img-2.svg';
import Gallery_9 from '../../images/gallery/img-3.svg';
import Gallery_10 from '../../images/gallery/img-4.svg';
import Gallery_11 from '../../images/gallery/img-5.svg';
import Gallery_12 from '../../images/gallery/img-6.svg';

import './style.css'



class Gallery2 extends Component {


    render() {

        return (
            <section id="protfolio" className="gallery-section s2 section-padding">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_1}
                                    image={Gallery_1} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_2}
                                    image={Gallery_2} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_3}
                                    image={Gallery_3} loading='lazy'/>
                            </div>
                        </div>
                       
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_5}
                                    image={Gallery_5} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_6}
                                    image={Gallery_6} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_7}
                                    image={Gallery_7} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_8}
                                    image={Gallery_8} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_9}
                                    image={Gallery_9} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_10}
                                    image={Gallery_10} loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_11}
                                    image={Gallery_11 }loading='lazy'/>
                            </div>
                        </div>
                        <div className="col col-lg-3 col-md-6 col-sm-6 col-12">
                            <div className="gallery-img">
                                <ReactFancyBox
                                    thumbnail={Gallery_12}
                                    image={Gallery_12} loading='lazy'/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Gallery2;